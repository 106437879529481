import React, { useState, useContext, useEffect, useMemo, useCallback } from "react";
import { GetUserInfo, UpdateUserInfo, GetPackages, CreateTransaction, CreateCustomPack, GetPriceList } from "../../API";
import { toast } from "react-toastify";
import PopUp from "../popUp";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { Context } from "../../context";
import { useTranslation } from "react-i18next";
import Field from "../../component/field";
import Table from "../../component/newTable";
import Select from "react-select";
import i18next from "i18next";
import "./style.css";
export default function UpdatePopUp({
  clientID
}) {
  const { updateUserPopUp, setUpdateUserPopUp, reload, setReload } = useContext(Context);
  const { t, i18n } = useTranslation();
  const [active, setActive] = useState();
  const [balance, setBalance] = useState();
  const [email, setEmail] = useState(null);
  const [contactPerson, setContactPerson] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [webAccess, setWebAccess] = useState();
  const [httpAccess, setHttpAccess] = useState();
  // const [operatorsList, setOperatorsList] = useState([]);
  const [paymentMethodList, setPaymentMethodList] = useState([
    { ID: 1, Name: t("Indoor") },
    { ID: 2, Name: t("Outdoor") },
    { ID: 3, Name: t("Cheque") },
    { ID: 4, Name: t("BankTransfer") },
    { ID: 5, Name: t("ElectronicWallet") },
    { ID: 6, Name: t("Demo") },
    { ID: 7, Name: t("VodafoneWallet") },
    { ID: 8, Name: t("OrangeWallet") },
    { ID: 9, Name: t("EtisalatWallet") },
    { ID: 10, Name: t("BankDeposit") }
  ]);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(-1);
  const [updateUserName, setUpdateUserName] = useState();
  const [isLoadingUpdate, setIsLoadingUpdate] = useState(false);
  const [isLoadingCreatePackBtn, setIsLoadingCreatePackBtn] = useState(false);
  const [isLoadingUserData, setIsLoadingUserData] = useState(false);
  const [customePackOpen, setCustomePackOpen] = useState(false);
  const [avilablePackOpen, setAvilablePackOpen] = useState(false);
  const [createPackOpen, setCreatePackOpen] = useState(false);
  const [personalInfoOpen, setPersonalInfoOpen] = useState(true);
  const [addPackName, setAddPackName] = useState("");
  const [addPackVolume, setAddPackVolume] = useState(null);
  const [addPackCost, setAddPackCost] = useState("");
  const [addEInvoice, setAddEInvoice] = useState("");
  const [selectedPaymentCustom, setSelectedPaymentCustom] = useState('1');
  const [selectedPaymentPacks, setSelectedPaymentPacks] = useState('1');
  const [priceList, setPriceList] = useState([]);
  const [selectedPriceList, setSelectedPriceList] = useState(null);
  const [data, setData] = useState([]);
  const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  const mobileNumberRegex = /^01[0125][0-9]{8}$/;

  useEffect(() => {
    if (!clientID)
      return;
    setIsLoadingUserData(true);
    GetUserInfo({
      "@clientID": clientID
    })
      .then((response) => {
        setIsLoadingUserData(false);
        setUpdateUserName(response.data.table1[0].UserName);
        setActive(response.data.table1[0].Active);
        setWebAccess(response.data.table1[0].Web_Access);
        setHttpAccess(response.data.table1[0].HTTP_Access);
        setEmail(response.data.table1[0].Email);
        setPhoneNumber(response.data.table1[0].PhoneNumber);
        setContactPerson(response.data.table1[0].ContactPerson);
      })
      .catch((error) => {
        setIsLoadingUserData(false);
      });

    GetPriceList({
    })
      .then((response) => {
        setPriceList(response.data.table1);
      })
      .catch((err) => {
        toast.error(`${err}`);
      });


    // GetClientOperator({
    //   "@clientID": clientID
    // })
    //   .then((response) => {
    //     setOperatorsList(response.data.table1);
    //   })
    //   .catch((error) => {
    //     setIsLoadingUserData(false);
    //     toast.error(`${error?.response?.data?.Message || error}`)
    //     console.log(error);
    //   });
  }, [clientID, updateUserPopUp])

  const handleAddPackVolumeChange = (event) => {
    const volume = event;
    setAddPackVolume(volume);
    if (volume && selectedPriceList) {
      const newCost = (selectedPriceList.value * volume).toFixed(2);
      setAddPackCost(newCost);
    }
  };

  // Event handler for changing selectedPriceList
  const handleSelectedPriceListChange = (selectedOption) => {
    setSelectedPriceList(selectedOption);
    if (addPackVolume && selectedOption) {
      const newCost = (selectedOption.value * addPackVolume).toFixed(2);
      setAddPackCost(newCost);
    }
  };

  const CustomePackShow = () => {
    setCustomePackOpen(true);
    setPersonalInfoOpen(false);
    setCreatePackOpen(false);
    setAvilablePackOpen(false);
  }
  const PersonalInfoShow = () => {
    setCustomePackOpen(false);
    setPersonalInfoOpen(true);
    setCreatePackOpen(false);
    setAvilablePackOpen(false);
    setAvilablePackOpen(false);
    setCustomePackOpen(false);
  }

  const avilablePackShow = () => {
    setCreatePackOpen(false);
    setAvilablePackOpen(true);
  }

  const createPackShow = () => {
    setCreatePackOpen(true);
    setAvilablePackOpen(false);
  }

  const customStyles = {
    control: (provided) => ({
      ...provided,
      fontSize: "16px",
      width: "100%",
      height: "48px"
    }),
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? "white" : "black",
      background: state.isSelected ? "blue" : "white",
      fontSize: "14px",
    }),
  };


  const columns = useMemo(
    () => [
      {
        Header: t("packName"),
        accessor: "Name",
      },
      {
        Header: t("packVolume"),
        accessor: "SmsVolume",
      },
      {
        Header: t("packCost"),
        accessor: "Cost",
        Cell: ({ row: { original } }) => {
          return <div>
            {original.Cost}
          </div>;
        },
      },
      {
        Header: "",
        accessor: "ID",
        Cell: ({ row: { original } }) => {
          return <div className="transactionReportActions">
            <button className="addBalanceBtn"
              onClick={() => executeTransaction(original.Cost, original.ID)} >
              {t("subscribe")}</button>
          </div>;
        },
      },

    ],
    [t, clientID, selectedPaymentPacks]
  );

  const fetchData = useCallback(
    ({ pageSize, pageIndex }) => {

      setData([]);
      GetPackages().then((res) => {
        setData(res.data.data.Table);
      });
    },
    [reload]
  );


  const createCustomePackageBtn = () => {

    if (addPackName == undefined || addPackName.trim() == "") {
      toast.error("Please enter package name");
      return;
    }

    if (addPackVolume == undefined || addPackVolume == "") {
      toast.error("Please enter package volume");
      return;
    }

    if (addPackCost == undefined || addPackCost == "") {
      toast.error("Please enter package cost");
      return;
    }
    if (selectedPaymentCustom == 1 && selectedPaymentMethod == -1) {
      toast.error("Please select payment method");
      return;
    }

    setIsLoadingCreatePackBtn(true);

    CreateCustomPack({
      "@clientID": clientID,
      "@packName": addPackName,
      "@packVolume": addPackVolume,
      "@packCost": addPackCost,
      "@paymentMethod": selectedPaymentCustom,
      "@PaymentType": selectedPaymentCustom == 1 ? selectedPaymentMethod.label : null,
      "@invoiceID": addEInvoice
    })
      .then((response) => {
        toast.success("Package created successfully");
        setUpdateUserPopUp(false);
        setAddPackName("");
        setAddPackVolume("");
        setAddPackCost("");
        setReload(!reload);
        setIsLoadingCreatePackBtn(false);
      })
      .catch((error) => {
        toast.error(error);
        setIsLoadingCreatePackBtn(false);
      });

  }

  const executeTransaction = (cost, packageID) => {
    CreateTransaction({
      "@clientID": clientID,
      "@amount": cost,
      "@packageID": packageID,
      "@paymentMethod": localStorage.getItem("admin") === "2" ? 2 : selectedPaymentPacks
    })
      .then((response) => {
        if (response.data.table1[0]?.limitExceeded == 1) {
          toast.error(t("limitExceededErr"));
          return;
        }
        if (response.data.table1[0].Column1 == 1 || response.data.table1[0].Column1 == 2) {
          setReload(!reload);
          toast.success("Package added successfully");
        }
        else if (response.data.table1[0].Column1 == 0) {

          toast.error(t("RechargeErr"));
        }

        setUpdateUserPopUp(false)
      })
      .catch((error) => {
        //     toast.error("Error Occure");
        console.log(error);
        //     setIsLoadingBtn(false);
      });
  };



  function handleChange(event) {
    setSelectedPaymentCustom(event.target.value);

  }

  function handleChangePaymentMethod(event) {
    setSelectedPaymentPacks(event.target.value);

  }


  const handleClose = () => {
    setUpdateUserPopUp(false)
    setBalance("");
    setAddPackCost("");
    setAddPackName("");
    setAddPackVolume("");
  };
  const popUpTitle = <div style={{ textAlign: "center" }}>
    <div>{t("update")}</div>
    {isLoadingUserData && <span className="spinner-border" role="status"></span>}
  </div>;

  const popUpFooter = (
    <>
      {(personalInfoOpen && (localStorage.getItem("userId") === "402" || localStorage.getItem("admin") !== "2")) &&
        <button className="footerPopUpBtns" onClick={() => updateUserBtn()} disabled={isLoadingUpdate}>
          {t("update")}
          {isLoadingUpdate && <span className="spinner-border" role="status"></span>}
        </button>
      }
      {createPackOpen &&
        <button className="footerPopUpBtns" onClick={() => createCustomePackageBtn()} disabled={isLoadingCreatePackBtn}>
          {t("create")}
          {isLoadingCreatePackBtn && <span className="spinner-border" role="status"></span>}
        </button>
      }
      <button
        className="closeBtn"
        onClick={handleClose}
        disabled={isLoadingUpdate}
      >
        {t("closeBtn")}
      </button>
    </>
  );
  const updateUserBtn = () => {
    // if (!emailRegex.test(email)) {
    //   toast.error(t("e-mailInvalid"));
    //   setIsLoadingUpdate(false);
    //   return;
    // }
    // if (!mobileNumberRegex.test(phoneNumber)) {
    //   toast.error(t("phoneNumberInvalid"));
    //   setIsLoadingUpdate(false);
    //   return;
    // }
    setIsLoadingUpdate(true);
    UpdateUserInfo({
      "@clientID": clientID,
      "@username": updateUserName,
      "@active": active,
      "@amount": balance || null,
      "@webAccess": webAccess,
      "@httpAccess": httpAccess,
      "@email": email,
      "@phoneNumber": phoneNumber,
      "@contactPerson": contactPerson
    })
      .then((response) => {
        handleClose();
        setReload(!reload);
        setIsLoadingUpdate(false);
        window.location.reload();
      })
      .catch((error) => {
        setIsLoadingUpdate(false);
      });

  };
  // const handlechangeOperator = (value, index) => {
  //   const newOperators = [...operatorsList];
  //   newOperators[index].Cost = value;
  //   setOperatorsList(newOperators);
  // };
  return (
    <>
      {(localStorage.getItem("admin") === "1" || localStorage.getItem("userId") === "402") &&
        <PopUp
          popUpTitle={popUpTitle}
          children={<div className={`form - group ${t.language}`}>
            <button className={personalInfoOpen ? "TransactionBtn titlepopupActive" : "TransactionBtn"} onClick={() => PersonalInfoShow()} >{t("personalInfo")}</button>
            <button className={customePackOpen ? "TransactionBtn titlepopupActive" : "TransactionBtn"} onClick={() => CustomePackShow()} >{t("packages")}</button>
            <hr className="dividerStyle" />
            {customePackOpen &&

              <>
                {(!createPackOpen && !avilablePackOpen) &&
                  <div className="userPackagesOptions">
                    <div className="packCard" onClick={() => avilablePackShow()}>
                      <img className="packsImg" src="images/chatIcon.png" alt="chat icon" />
                      <div className="packCardTitle">{t("ourPacks")}</div>
                    </div>
                    <div className="packCard" onClick={() => createPackShow()}>
                      <img className="packsImg" src="images/custome.png" alt="custome icon" />
                      <div className="packCardTitle">{t("customPack")}</div>
                    </div>
                  </div>
                }
                {avilablePackOpen &&
                  <div className={`packList ${i18n.language}`}>
                    <Table
                      columns={columns}
                      data={data}
                      fetchData={fetchData}
                      TotalRows={10}
                    />
                    <div className={`paymentMethods ${i18n.language}`}>
                      <FormControl>
                        <div className={`paymentMethodsLabel ${i18n.language}`}><FormLabel >{t("payment")}</FormLabel></div>
                        <RadioGroup
                          row
                          onChange={handleChangePaymentMethod}
                          value={selectedPaymentPacks}
                        >
                          <FormControlLabel value="1" control={<Radio className="radioBtnStyle" checked={selectedPaymentPacks === '1'} />} label={<div className="paymentMethodDiv">
                            <img
                              src="images/money.png"
                              alt="money icon"
                            />{t("paid")}</div>} />
                          <FormControlLabel value="2" control={<Radio className="radioBtnStyle" />} label={<div className="paymentMethodDiv">
                            <img
                              src="images/credit-card.png"
                              alt="credit card icon"
                            />
                            {t("unPaid")}</div>} />
                        </RadioGroup>
                      </FormControl>
                    </div>
                  </div>
                }
                {createPackOpen &&
                  <>
                    <Field
                      inputFieldId={"addPackName"}
                      inputFieldType={"text"}
                      labelName={t("packName")}
                      placeholder={t("packName")}
                      inputFieldValue={addPackName}
                      setOnChange={setAddPackName}
                    />
                    <br></br>
                    <Field
                      inputFieldId={"addPackVolume"}
                      inputFieldType={"number"}
                      labelName={t("packVolume")}
                      placeholder={t("packVolume")}
                      inputFieldValue={addPackVolume}
                      setOnChange={handleAddPackVolumeChange}
                    />
                    <br></br>

                    <div>
                      <label className={`listLabelStyle ${i18next.language}`} htmlFor="priceList">{t("PriceList")}</label>
                      <Select
                        className="form-select"
                        value={selectedPriceList}
                        onChange={handleSelectedPriceListChange}
                        styles={customStyles}
                        options={priceList.map((p) => ({
                          value: p.PriceListValue,
                          label: p.PriceListName + " (" + p.PriceListValue + ") " + t("currency"),
                        }))}
                        placeholder={t("PriceList")}
                      />
                    </div>

                    <br></br>
                    <Field
                      inputFieldId={"addPackCost"}
                      inputFieldType={"number"}
                      labelName={t("packCost")}
                      placeholder={t("packCost")}
                      inputFieldValue={addPackCost}
                      setOnChange={setAddPackCost}
                      isDisabled={true}
                    />
                    <div className={`paymentMethods ${i18n.language}`}>
                      <div className={`paymentMethodsLabel ${i18n.language}`}><FormLabel >{t("payment")}</FormLabel></div>
                      <RadioGroup
                        row
                        onChange={handleChange}
                        value={selectedPaymentCustom}
                      >
                        <FormControlLabel value="1" control={<Radio className="radioBtnStyle" checked={selectedPaymentCustom === '1'} />} label={<div className="paymentMethodDiv">
                          <img
                            src="images/money.png"
                            alt="money icon"
                          />Paid</div>} />
                        <FormControlLabel value="2" control={<Radio className="radioBtnStyle" />} label={<div className="paymentMethodDiv">
                          <img
                            src="images/credit-card.png"
                            alt="credit card icon"
                          />
                          UnPaid</div>} />
                      </RadioGroup>
                      {selectedPaymentCustom == 1 && (
                        <>
                          <Field
                            inputFieldId={"addEInvoice"}
                            inputFieldType={"number"}
                            labelName={t("e-invoice")}
                            placeholder={t("e-invoice")}
                            inputFieldValue={addEInvoice}
                            setOnChange={setAddEInvoice}
                          />
                          <br></br>
                          <Select
                            id="clientDL"
                            className="form-select"
                            value={selectedPaymentMethod}
                            onChange={setSelectedPaymentMethod}
                            options={paymentMethodList.map((c) => ({
                              value: c.ID,
                              label: c.Name,
                            }))}
                            placeholder={t("paymentMethodWay")}
                          />
                        </>
                      )}
                    </div>
                  </>
                }
              </>
            }

            {personalInfoOpen &&
              <>
                <Field
                  inputFieldId={"updateUsername"}
                  inputFieldType={"text"}
                  labelName={t("userName")}
                  placeholder={t("userName")}
                  inputFieldValue={updateUserName}
                  setOnChange={setUpdateUserName}
                  isDisabled={isLoadingUserData}
                />
                <br></br>
                <Field
                  inputFieldId={"updateUserEmail"}
                  inputFieldType={"text"}
                  labelName={t("email")}
                  placeholder={t("email")}
                  inputFieldValue={email}
                  setOnChange={setEmail}
                  isDisabled={isLoadingUserData}
                />
                <br></br>
                <Field
                  inputFieldId={"updateUserPhone"}
                  inputFieldType={"number"}
                  labelName={t("phoneNumber")}
                  placeholder={t("phoneNumber")}
                  inputFieldValue={phoneNumber}
                  setOnChange={setPhoneNumber}
                  isDisabled={isLoadingUserData}
                />
                <br></br>
                <br></br>
                <Field
                  inputFieldId={"updateUserContactPerson"}
                  inputFieldType={"text"}
                  labelName={t("contactPerson")}
                  placeholder={t("contactPerson")}
                  inputFieldValue={contactPerson}
                  setOnChange={setContactPerson}
                  isDisabled={isLoadingUserData}
                />
                <br></br>
                <Field
                  inputFieldId={"updateUserBalance"}
                  inputFieldType={"number"}
                  labelName={t("credit")}
                  placeholder={t("addBalance")}
                  inputFieldValue={balance}
                  setOnChange={setBalance}
                  isDisabled={isLoadingUserData}
                />



                <div className={`updatePopUpCheckBox ${i18next.language}`}>
                  <input
                    id="activeCheckBox"
                    type="checkbox"
                    onChange={() => setActive(!active)}
                    value={active}
                    checked={active}
                    disabled={isLoadingUserData}
                  />
                  <label className="checkBoxLabel">{t("active")}</label>
                </div>
                <div className={`updatePopUpCheckBox ${i18next.language}`}>
                  <input
                    id="webAccessCheckBox"
                    type="checkbox"
                    onChange={() => setWebAccess(!webAccess)}
                    value={webAccess}
                    checked={webAccess}
                    disabled={isLoadingUserData}
                  />
                  <label className="checkBoxLabel">{t("webAccess")}</label>
                </div>
                <div className={`updatePopUpCheckBox ${i18next.language}`}>
                  <input
                    id="httpAccessCheckBox"
                    type="checkbox"
                    onChange={() => setHttpAccess(!httpAccess)}
                    value={httpAccess}
                    checked={httpAccess}
                    disabled={isLoadingUserData}
                  />
                  <label className="checkBoxLabel">HTTP-Access</label>
                </div>
                {/* <div className="operatorTitle">{t("operators")}</div>
              <div className="operatorsContainer">
                {operatorsList.map((o, i) => (
                  <div>
                    <div className="operatorName">
                      {o.Operator_Name}
                    </div>
                    <input
                      type="number"
                      className="form-control operatorCost"
                      value={o.Cost}
                      onChange={(e) => handlechangeOperator(e.target.value, i)}
                      disabled={isLoadingUserData}
                    />
                  </div>

                ))}
              </div> */}
              </>}
          </div>}
          popUpFooter={popUpFooter}
          showPopUp={updateUserPopUp}
          closePopUp={handleClose}
        />}

      {(localStorage.getItem("admin") === "2" && localStorage.getItem("userId") !== "402") &&
        <PopUp
          popUpTitle={popUpTitle}
          children={<div className={`form-group ${t.language}`}>

            <>
              <div className={`packList ${i18n.language}`}>
                <Table
                  columns={columns}
                  data={data}
                  fetchData={fetchData}
                  TotalRows={10}
                />
              </div>
            </>
          </div>}
          popUpFooter={popUpFooter}
          showPopUp={updateUserPopUp}
          closePopUp={handleClose}
        />}


    </>
  );
}
