import React, { useState, useMemo, useCallback, useContext, useRef } from "react";
import { GetAllUserPackages, GetAllUserPackagesExport, EditInvoiceNum } from "../../API";
import { Context } from "../../context";
import { toast } from "react-toastify";
import Table from "../../component/newTable";
import { useTranslation } from "react-i18next";
import CurrencyFormat from "react-currency-format";
import Header from "../../component/header";
import DateRangePicker from "../../component/dateRangePicker";
import "./style.css";

export default function PackagesHistory() {
  const [isLoading, setIsLoading] = useState(false);
  const [pageCount, setPageCount] = useState();
  const [isLoadingBtn, setIsLoadingBtn] = useState(false);
  const [userNameFilter, setUserNameFilter] = useState();
  const { startDate, endDate, reload, setReload } = useContext(Context);
  const [data, setData] = useState([]);
  const [toggleSideBar] = useState(false);
  const [editableRowID, setEditableRowID] = useState(null);
  const inputRef = useRef(null);

  const { t } = useTranslation();
  const isAdmin = localStorage.getItem("admin") === "1" || localStorage.getItem("admin") === "2";

  const exportHandler = () => {
    setIsLoadingBtn(true);
    GetAllUserPackagesExport({
      "@username": userNameFilter,
      "@startDate": new Intl.DateTimeFormat("en").format(startDate),
      "@endDate": new Intl.DateTimeFormat("en").format(endDate),
    })
      .then((response) => {
        setIsLoadingBtn(false);
        let link = document.createElement("a");

        link.download = "PackagesReport";
        link.href =
          "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," +
          response.data;

        if (/android|iPad|iPhone|iPod/i.test(navigator.userAgent))
          link.download += ".xlsx";

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((error) => {
        setIsLoadingBtn(false);
      });
  };

  const saveInvoice = (packID, invoiceNum) => {
    EditInvoiceNum({
      "@packID": packID,
      "@invoiceID": invoiceNum
    })
      .then((response) => {
        console.log(response.data.table1[0].success);
        if (response.data.table1[0].success)
          toast.success(t("InvoiceUpdated"));
        else
          toast.error(t("errorMessage"));
        setReload(!reload);
      })
      .catch((error) => {
        console.log(error);
        toast.error(t("errorMessage"));
      });
    setEditableRowID(null);

  };

  const columns = useMemo(
    () => [
      {
        Header: t("ID"),
        accessor: "ID",
      },
      {
        Header: t("packVolume"),
        accessor: "Balance",
      },
      {
        Header: t("packCost") + " (EGP)",
        accessor: "Cost",
        Cell: ({ row: { original } }) => {
          return (
            <CurrencyFormat
              value={original.Cost}
              displayType={"text"}
              thousandSeparator={true}
            />
          );
        },
      },
      {
        Header: t("smsPrice") + " (EGP)",
        accessor: "smsPrice",
        Cell: ({ row: { original } }) => {
          return (
            <CurrencyFormat
              value={(original.Cost / original.Balance).toFixed(2)}
              displayType={"text"}
              thousandSeparator={true}
            />
          );
        },
      },
      {
        Header: (
          <div className="tableHeaderFilter">
            <div>{t("userName")}</div>
            <input
              type="text"
              className="searchFilter"
              value={userNameFilter}
              onChange={(e) => setUserNameFilter(e.target.value)}
              placeholder="Search"
            />
          </div>
        ),
        accessor: "UserName",
      },
      {
        Header: t("Date"),
        accessor: "CreatedDate",
      },
      {
        Header: t("InvoiceID"),
        accessor: "invoiceID",
        Cell: ({ row: { original } }) => {
          const isEditable = editableRowID === original.ID;
          const [editableInvoiceNum, setEditableInvoiceNum] = useState(original.invoiceID || "");
          const handleInputChange = (e) => {
            if (isEditable) {
              setEditableInvoiceNum(e.target.value);
            }
          };
          const handleEditClick = () => {
            setEditableRowID(original.ID);
            setEditableInvoiceNum(original.invoiceID || "");

            setTimeout(() => {
              if (inputRef.current) {
                inputRef.current.focus();
              }
            }, 0);
          };
          return (
            isAdmin ?
              <div className="editable-cell-container">
                <div className="tableHeaderFilter">
                  <input
                    ref={inputRef}
                    type="number"
                    className="searchFilter"
                    value={editableInvoiceNum}
                    onChange={handleInputChange}
                    placeholder="Invoice Number"
                    disabled={!isEditable}
                  />
                </div>
                {!isEditable ? (
                  <i
                    className="fas fa-pen editIconStyle"
                    onClick={handleEditClick}
                  ></i>
                ) : (
                  <i className="fas fa-save editIconStyle" onClick={() => saveInvoice(original.ID, editableInvoiceNum)}></i>
                )}

              </div> : original.invoiceID == null ? "-" : original.invoiceID
          );
        },
      },
      {
        Header: t("paymentMethodWay"),
        accessor: "PaymentMethod",
        Cell: ({ row: { original } }) => {
          return (
            original.PaymentMethod != null ? original.PaymentMethod : "-"
          );
        },
      },
    ],
    [t, editableRowID]
  );

  const fetchData = useCallback(
    ({ pageSize, pageIndex }) => {
      setIsLoading(true);
      setData([]);
      GetAllUserPackages({
        "@page": pageIndex,
        "@rowsPerPage": pageSize,
        "@username": userNameFilter,
        "@startDate": new Intl.DateTimeFormat("en").format(startDate),
        "@endDate": new Intl.DateTimeFormat("en").format(endDate),
      })
        .then((response) => {
          setIsLoading(false);
          setData(response.data.table1);
          setPageCount(response.data.table2[0].Total);
        })
        .catch((error) => {
          setIsLoading(false);
        });
    },
    [t, userNameFilter, startDate, endDate, reload]
  );

  return (
    <div className="transactionContainer">
      <Header
        title={t("PackagesHistoryTitle")}
        toggleSideBar={() => toggleSideBar((s) => !s)}
      />
      <div id="packagesHistoryExport">
        <DateRangePicker />
        <button
          className="exportBtn"
          onClick={() => exportHandler()}
          disabled={isLoadingBtn}
        >
          {t("exportReport")}
          {isLoadingBtn && (
            <span className="spinner-border" role="status"></span>
          )}
        </button>
      </div>
      <div className="tableDiv">
        <Table
          columns={columns}
          data={data}
          fetchData={fetchData}
          loading={isLoading}
          TotalRows={pageCount}
          tableHeader={t("PackagesHistoryTitle")}
        />
      </div>
    </div>
  );
}
